import { render, staticRenderFns } from "./TaskLogs.vue?vue&type=template&id=486e30ee&scoped=true&"
import script from "./TaskLogs.vue?vue&type=script&lang=js&"
export * from "./TaskLogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486e30ee",
  null
  
)

export default component.exports